import React, { useState, useEffect } from "react";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import useSiteMetadata from "../components/SiteMetadata";
import { nb, enGB, sv } from "date-fns/locale";
import { format } from "date-fns";
import styled from "styled-components";
import {
  sizes,
  colorAliases,
  colors,
  fontSizes,
  borderRadius,
  borders,
  boxShadow,
  MOBILEBREAKPOINT,
} from "../style/design-system";
import OuterContainer from "../components/OuterContainer";
import NewsletterBox from "../components/NewsletterBox";

const SectionInnerContainer = styled.div`
  width: 100%;
  max-width: ${sizes.siteWidth};
  margin: 0 auto;
  padding: ${sizes["2xl"]} ${sizes.lg} ${sizes.lg} ${sizes.lg};
`;

const TagsContainer = styled.div``;

const Tag = styled(Link)`
  text-transform: uppercase;
  color: ${colorAliases.red};
  margin-right: ${sizes.md};
  text-decoration: none;
  letter-spacing: 2px;
  font-size: ${fontSizes.sm};

  &:hover {
    text-decoration: underline;
  }
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: ${fontSizes["3xl"]};
  margin: ${sizes["xl"]} 0 ${sizes.sm} 0;
`;

const FormattedDate = styled.h5`
  color: ${colorAliases.grayText};
  font-weight: 300;
`;

const FeaturedImageWrapper = styled.div`
  max-height: 500px;
  margin: ${sizes["2xl"]} 0;
  border-radius: ${borderRadius.md};
  overflow: hidden;
`;

const FeaturedImage = styled.img`
  width: 100%;
`;

const TwoColumns = styled.div`
  display: flex;
  @media (max-width: ${MOBILEBREAKPOINT}) {
    display: block;
  }
`;

const Left = styled.div`
  width: 100%;
  margin-right: ${sizes["2xl"]};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin-right: 0;
  }
`;

const Right = styled.div`
  width: 30%;
  max-width: 300px;
  @media (max-width: ${MOBILEBREAKPOINT}) {
    display: none;
  }
`;

const Description = styled.p`
  margin: 0 0 ${sizes.md};
  font-weight: 400;
  font-size: ${fontSizes.lg};
  line-height: 1.5;
`;

const localesMapper = {
  en: enGB,
  no: nb,
  se: sv,
};

export const BlogPostTemplate = ({
  location,
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  date,
  featuredImage,
  language,
}) => {
  const PostContent = contentComponent || Content;
  const formattedDate = format(new Date(date), `PP`, {
    locale: localesMapper[language],
  });

  return (
    <section>
      {helmet || ""}
      <OuterContainer>
        <SectionInnerContainer>
          <TagsContainer>
            {tags &&
              tags.length &&
              tags.map((tag) => (
                <Tag to={`/tags/${kebabCase(tag)}/`} key={tag}>
                  {tag}
                </Tag>
              ))}
          </TagsContainer>

          <Title>{title}</Title>
          <FormattedDate>{formattedDate}</FormattedDate>
          <FeaturedImageWrapper>
            <FeaturedImage src={`/img/${featuredImage}`}></FeaturedImage>
          </FeaturedImageWrapper>

          <TwoColumns>
            <Left>
              <Description>{description}</Description>
              <div className="post-content">
                <PostContent content={content} />
              </div>
            </Left>
            {/* <Right>
              <NewsletterBox></NewsletterBox>
            </Right> */}
          </TwoColumns>
        </SectionInnerContainer>
      </OuterContainer>
    </section>
  );
};

const BlogPost = ({ data, location }) => {
  const { markdownRemark: post } = data;

  const { siteUrl } = useSiteMetadata();

  return (
    <Layout language={post.frontmatter.language}>
      <BlogPostTemplate
        location={location}
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate={`%s | ${post.frontmatter.metaTitle}`}>
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <link rel="canonical" href={siteUrl + location.pathname} />
            <meta
              property="og:image"
              content={`https://dflow.com/img/${post.frontmatter.featuredimage.relativePath}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        featuredImage={post.frontmatter.featuredimage.relativePath}
        language={post.frontmatter.language}
      />
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        metaTitle
        language
        date
        title
        description
        tags
        featuredimage {
          relativePath
        }
      }
    }
  }
`;
